import { Col, Tooltip, theme, Input, Button } from 'antd'
import { t } from 'i18next'
import { FC, useMemo } from 'react'
import { ITransportFee } from 'src/Interface/ShipmentFeeInterface'
import ComboBox from 'src/components/ComboBox'
import M22Box from 'src/components/M22Box'
import { formatMoneyByUnit } from 'src/core/helpers/string'

interface IProps {
  allowCreate: boolean
  expectedPackages: any
  volume: any
  isSubmitted: boolean
  refShipmentCode: string
  refCustomerCode: string
  loadingCreateShipment: boolean
  transportFees: ITransportFee[] | undefined
  submitCreateShipmentHandler: () => void
  setRefCustomerCode: (refCustomerCode: string) => void
  setRefShipmentCode: (refShipmentCode: string) => void
  setExpectedPackages: (expectedPackages: any) => void
}

export const OrderFees: FC<IProps> = ({
  allowCreate,
  expectedPackages,
  volume,
  isSubmitted,
  refShipmentCode,
  refCustomerCode,
  transportFees,
  submitCreateShipmentHandler,
  loadingCreateShipment,
  setRefCustomerCode,
  setRefShipmentCode,
  setExpectedPackages,
}) => {
  const totalFee: number | undefined = useMemo(() => {
    return transportFees?.reduce((total, fee) => total + fee.fee, 0)
  }, [transportFees])

  return (
    <Col span={7}>
      <M22Box className="pd-16">
        <div className="flex flex-col">
          <span className="flex fsz-16 line-h-24 mg-bt-16 robotomedium width100pc align-items-center">
            Phí Tạm Tính{' '}
            <Tooltip title={t('consignment.feeTooltip')}>
              <i className="cursor-pointer mg-l-4 fsz-14 txt-color-gray7 fa-regular fa-circle-info txt-color-orange2"></i>
            </Tooltip>
          </span>

          {transportFees && (
            <div className="box-fees__details width100pc">
              {transportFees?.map((fee) => (
                <div
                  key={fee.code}
                  className="flex align-items-center justify-content-between mg-bt-14">
                  <span className="label txt-color-gray7 fw-300">{fee.name}</span>
                  <span className="value fsz-16 line-h-24">{fee.fee > -1 ? formatMoneyByUnit(fee.fee, '') : '---'}</span>
                </div>
              ))}

              <div className={'custom-line-dash'}></div>
              <div className="flex align-items-center justify-content-between mg-t-16 mg-bt-16">
                <span className="label robotomedium fsz-20 line-h-23">{t(`shipments.totalFeesTemp`)}</span>
                {totalFee! > 1 && <span className="value robotobold fsz-20 line-h-23">{formatMoneyByUnit(totalFee, '')}</span>}
              </div>
            </div>
          )}

          {!transportFees && (
            <span className={`txt-color-gray8 mg-bt-16 ${theme}`}>
              Quý khách vui lòng chọn các thông tin hàng hoá, thông tin vận chuyển và nhà cung cấp để có thể tạm tính phí nhé!
            </span>
          )}

          <div className="flex flex-col mg-bt-16">
            <ComboBox
              label="Số lượng kiện dự kiến"
              value={expectedPackages}
              className="mg-bt-18">
              <Input
                placeholder=""
                pattern={'^[0-9\b]+$'}
                size="large"
                value={expectedPackages}
                onChange={(e) => setExpectedPackages((v: any) => (e.target.validity.valid ? e.target.value : v))}
              />
            </ComboBox>

            <ComboBox
              className={`mg-bt-${!volume && isSubmitted ? '34' : 18}`}
              tooltip={
                <Tooltip
                  className={'float-label-tooltip'}
                  overlayClassName="consignment-tooltip"
                  title={t('consignment.codeOrderOnSystem')}>
                  <i className="cursor-pointer fsz-12 txt-color-gray7 fa-regular fa-circle-info pd-l-4 pd-r-4"></i>
                </Tooltip>
              }
              label={<span>Mã đơn hàng của bạn </span>}
              value={refShipmentCode}>
              <Input
                placeholder=""
                size="large"
                onChange={(e) => {
                  setRefShipmentCode(e.target.value)
                }}
              />
            </ComboBox>

            <ComboBox
              tooltip={
                <Tooltip
                  className={'float-label-tooltip'}
                  overlayClassName="consignment-tooltip"
                  title={t('consignment.codeCustomerOnSystem')}>
                  <i className="cursor-pointer fsz-12 txt-color-gray7 fa-regular fa-circle-info pd-l-4 pd-r-4"></i>
                </Tooltip>
              }
              label={<span>Mã khách hàng của bạn </span>}
              value={refCustomerCode}>
              <Input
                placeholder=""
                size="large"
                onChange={(e) => {
                  setRefCustomerCode(e.target.value)
                }}
              />
            </ComboBox>
          </div>
        </div>

        <Button
          className={`width100pc m22-btn ${theme}`}
          type="primary"
          disabled={allowCreate}
          onClick={submitCreateShipmentHandler}
          loading={loadingCreateShipment}>
          Tạo Đơn <i className="fa-solid fa-arrow-right"></i>
        </Button>
      </M22Box>
    </Col>
  )
}
