import { Empty, Button, Avatar, theme } from 'antd'
import { t } from 'i18next'
import AddressInfo from '../AddressInfo'
import EmptyImage from '../../../../resources/images/empty-2.png'
import DeliveryAddressInterface from 'src/Interface/DeliveryAddressInterface'
import { useState } from 'react'

interface IProps {
  deliveryAddresses: DeliveryAddressInterface[] | undefined
  deliveryAddress: DeliveryAddressInterface | undefined
  noticeWarehouse: string
  setOpenAddressList: (isOpen: boolean) => void
  setOpenModalAdd: (isOpen: boolean) => void
  setNoticeWarehouse: (notice: string) => void
}

export const AddressOptions: React.FC<IProps> = ({
  deliveryAddresses,
  deliveryAddress,
  noticeWarehouse,
  setOpenAddressList,
  setOpenModalAdd,
  setNoticeWarehouse,
}) => {
  const [noticeOneTime, setNoticeOneTime] = useState(true)

  return (
    <>
      <div className="flex align-items-center mg-bt-16 justify-content-between">
        <span className="fsz-16 line-h-24 robotomedium mg-r-12">{t('shipment.address')}</span>
        <span className="box-divider mg-r-12"></span>
        {deliveryAddresses && deliveryAddresses.length > 0 && (
          <div
            className={`edit-address px-0 txt-secondary-hover cursor-pointer`}
            onClick={() => setOpenAddressList(true)}>
            <span>
              <i className="fa-regular fa-location-dot mg-r-6"></i>
              {t('shopping-cart.changeAddress')}
            </span>
          </div>
        )}
      </div>

      {deliveryAddresses?.length === 0 && (
        <Empty
          className="empty-address"
          description={
            <div className="flex flex-col align-items-center">
              <span className="mg-l-2 whitespace mg-bt-8 txt-color-gray4">{t('shipments.addressEmpty')}</span>
              <Button
                className="w-fit-concent m22-btn"
                type="primary"
                icon={<i className="fa-solid fa-plus mg-r-10"></i>}
                onClick={() => setOpenModalAdd(true)}>
                {t('shipments.createAddress')}
              </Button>
            </div>
          }
          image={
            <Avatar
              size={32}
              shape="square"
              className={'icon-empty'}
              src={EmptyImage}
            />
          }
        />
      )}

      {deliveryAddresses && deliveryAddresses?.length > 0 && (
        <div className={`box-services__addresses ${theme}`}>
          <AddressInfo
            className="mg-bt-8"
            icon={
              <span className="w-14 mg-r-12 address-info-icon">
                <i className="fa-regular fa-user mg-r-12 fsz-12"></i>
              </span>
            }
            value={<span className="robotomedium">{deliveryAddress?.receiver}</span>}
            valueClasses="receiver"
          />
          <AddressInfo
            className="mg-bt-8"
            icon={
              <span className="w-14 mg-r-12 address-info-icon">
                <i className="fa-regular fa-house-chimney mg-r-12 fsz-12"></i>
              </span>
            }
            value={`${deliveryAddress?.address1 ? deliveryAddress?.address1 : ''} ${deliveryAddress?.display ? deliveryAddress?.display : ''}`}
          />
          <AddressInfo
            className="mg-bt-8"
            icon={
              <span className="w-14 mg-r-12 address-info-icon">
                <i className="fa-regular fa-phone-flip mg-r-12 fsz-12"></i>
              </span>
            }
            value={deliveryAddress?.phoneNumber}
          />
          {deliveryAddress && deliveryAddress.isDefault && (
            <AddressInfo
              icon={
                <span className="w-14 mg-r-12 address-info-icon">
                  <i className="fa-regular fa-gear mg-r-12 fsz-12"></i>
                </span>
              }
              value={t('address.status-isDefault')}
            />
          )}

          {noticeWarehouse && noticeOneTime && (
            <div className={`box-services__addresses-notice_${theme} mg-t-8`}>
              <span className={'txt-color-primary icon-notice'}>
                <i className="fas fa-exclamation-circle"></i>
              </span>
              <span
                className="whitespace-pre-wrap m22-typo-content break-word mg-r-12"
                dangerouslySetInnerHTML={{
                  __html: t('cart.not_preferred_warehouse', {
                    code: noticeWarehouse,
                  }),
                }}></span>
              <i
                className="cursor-pointer fas fa-times"
                onClick={() => {
                  setNoticeWarehouse('')
                  setNoticeOneTime(false)
                }}></i>
            </div>
          )}
        </div>
      )}
    </>
  )
}
