import { Row, Col, Input } from 'antd'
import ComboBox from 'src/components/ComboBox'
import { formatNumber } from 'src/core/helpers/string'
import { formatNumberWeight } from 'src/utils/util'
import SelectionItem from '../SelectinItem'

interface IProps {
  hsCode: string | undefined
  setHsCode: (value: string) => void
  formGroup: string
  setFormGroup: (value: string) => void
  valueOfProduct: string | undefined
  setValueOfProduct: (value: string) => void
  weight: number | null
  setWeight: (value: number | null) => void
  volume: number | null
  setVolume: (value: string | null) => void
  isSubmitted: boolean
  industryGroup: any
  fetchTransportTypesData: any
}

export const ProductInfo: React.FC<IProps> = ({
  hsCode,
  setHsCode,
  formGroup,
  setFormGroup,
  valueOfProduct,
  setValueOfProduct,
  weight,
  setWeight,
  volume,
  setVolume,
  isSubmitted,
  industryGroup,
  fetchTransportTypesData,
}) => {
  return (
    <Row
      gutter={16}
      className="">
      <Col span={12}>
        <div className="flex flex-col">
          <SelectionItem
            labelFloat={
              <span>
                Nhóm ngành hàng <span className="txt-color-red2">*</span>
              </span>
            }
            value={hsCode}
            options={industryGroup}
            selectIndustry
            isSubmitted={isSubmitted}
            valueChangeHandler={(val: any) => setHsCode(val)}
          />
          {!hsCode && isSubmitted && <span className="mg-bt-10 fsz-12 line-h-20 txt-color-red">Vui lòng chọn nhóm ngành hàng!</span>}
        </div>
      </Col>
      <Col span={12}>
        <div className="flex flex-col">
          <SelectionItem
            labelFloat={
              <span>
                Hình thức <span className="txt-color-red2">*</span>
              </span>
            }
            options={!fetchTransportTypesData.error && fetchTransportTypesData.data}
            isSubmitted={isSubmitted}
            value={formGroup}
            valueChangeHandler={(val: any) => setFormGroup(val)}
          />

          {!formGroup && isSubmitted && <span className="mg-bt-10 fsz-12 line-h-20 txt-color-red">Vui lòng chon hình thức!</span>}
        </div>
      </Col>
      <Col span={8}>
        <ComboBox
          value={valueOfProduct}
          className={`mg-bt${isSubmitted && !valueOfProduct ? '-4' : '-18'}`}
          label={
            <span>
              Giá trị hàng hoá (VND) <span className="txt-color-red2">*</span>
            </span>
          }>
          <Input
            value={valueOfProduct ? formatNumber(valueOfProduct) : ''}
            placeholder=""
            size="large"
            onChange={(e) => {
              setValueOfProduct(e.target.value.replaceAll('-', ''))
            }}
          />
        </ComboBox>
        {!valueOfProduct && isSubmitted && <span className="mg-bt-10 fsz-12 line-h-20 txt-color-red">Vui lòng nhập giá trị hàng hoá!</span>}
      </Col>
      <Col span={8}>
        <ComboBox
          label={
            <span>
              Khối lượng (kg) <span className="txt-color-red2">*</span>
            </span>
          }
          value={weight}
          className="mg-bt-18">
          <Input
            placeholder=""
            size="large"
            value={weight ? formatNumberWeight(weight) : ''}
            onChange={(e: any) => {
              const inputValue = e.target.value
              const formattedValue = inputValue
                .replace(/\./g, '')
                .replace(/,/g, '.')
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1')
              setWeight(formattedValue)
            }}
            // max={1000}
          />
        </ComboBox>
        {!weight && isSubmitted && <span className="mg-bt-10 fsz-12 line-h-20 txt-color-red">Vui lòng nhập khối lượng!</span>}
      </Col>
      <Col span={8}>
        <ComboBox
          label={
            <span>
              Thể tích (m3) <span className="txt-color-red2">*</span>
            </span>
          }
          value={volume}
          className="mg-bt-18">
          <Input
            placeholder=""
            size="large"
            value={volume ? formatNumberWeight(volume) : ''}
            onChange={(e) => {
              const inputValue = e.target.value
              const formattedValue = inputValue
                .replace(/\./g, '')
                .replace(/,/g, '.')
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1')
              setVolume(formattedValue)
            }}
          />
        </ComboBox>
        {!volume && isSubmitted && <span className="mg-bt-10 fsz-12 line-h-20 txt-color-red">Vui lòng chọn thể tích!</span>}
      </Col>
    </Row>
  )
}
