import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ComboBox from '../../../components/ComboBox'
import { useTheme } from '../../../hooks/useTheme'

interface SelectionItemProps {
  isSubmitted: boolean
  value?: string | number
  valueChangeHandler: (val: any) => void
  options: any
  labelFloat: any
  selectIndustry?: boolean
}
const { Option } = Select

const SelectionItem: React.FC<SelectionItemProps> = ({ labelFloat, isSubmitted, value, valueChangeHandler, options, selectIndustry }) => {
  const [valueLabel, setValueLabel] = useState('')
  const [dropdownVisibleChange] = useState(false)
  const [theme] = useTheme()
  const valueLabelChangeHandler = (val: string) => {
    setValueLabel(val)
  }

  useEffect(() => {
    if (!value) {
      setValueLabel('')
    }
  }, [value])

  return (
    <ComboBox
      label={labelFloat}
      className={`mg-bt${isSubmitted && !value ? '-4' : '-18'}`}
      value={value}>
      <Select
        className={value && !dropdownVisibleChange ? 'selector-selected' : dropdownVisibleChange ? 'selector-focused' : ''}
        style={{ width: '100%' }}
        onChange={(value) => valueChangeHandler(value)}
        placeholder={valueLabel}
        popupClassName={`${theme}`}
        onDropdownVisibleChange={() => {
          if (!value) {
            valueLabelChangeHandler('')
          }
        }}
        showSearch
        filterOption={(input, option) => cleanString(String(option!.children) ?? '').includes(cleanString(input))}
        value={valueLabel ? undefined : value ? value : ''}
        size="large"
        suffixIcon={<i className={`fa-regular fa-angle-${dropdownVisibleChange ? 'up' : 'down'}`}></i>}>
        {options &&
          options.length > 0 &&
          options?.map((x: any) => (
            <Option
              key={x.code}
              value={x.code}
              onMouseOver={() => {
                if (!value) {
                  valueLabelChangeHandler(selectIndustry ? x.name.split('.')[1] : x.name)
                }
              }}>
              {x.name}
            </Option>
          ))}
      </Select>
    </ComboBox>
  )
}

export default SelectionItem

function cleanString(str: string) {
  return str
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}