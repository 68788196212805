import { ConfigProvider } from 'antd'
import { useContext, useEffect, useState } from 'react'

import Dashboard from './containers/Dashboard'
import OrderDetail from './containers/OrderDetail'
import Orders from './containers/Orders'
import Packages from './containers/Packages'
import ShoppingCart from './containers/ShoppingCart'
import Transaction from './containers/Transaction'

import { isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/locale/vi'
import numeral from 'numeral'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { AuthenticateRoute } from './components/AuthenticateRoute'
import PageNotFound from './components/ErrorPage/404'
import { Authentication } from './containers/Authentication'
import Claims from './containers/Claim'
import ClaimCreate from './containers/ClaimCreate'
import { ClaimDetail } from './containers/ClaimDetail'
import CreateRequestDelivery from './containers/Delivery/Create'
import Success from './containers/Delivery/Create/Success'
import InventoryVoucher from './containers/Delivery/InventoryVoucher'
import RequestDelivery from './containers/Delivery/Request'
import { Login } from './containers/Login'
import Profile from './containers/Profile'
import Shipments from './containers/Shipment'
import Shipment from './containers/Shipment/Detail'
import { LANGUAGE_STORAGE_KEY } from './core/config'
import { TenantUpdateConfigCommand } from './Interface/TenantUpdateConfigCommand'
import Consignment from './containers/Shipment/Create'
import CurrentConfigProvider, { CurrentContext } from './context/CurrentContext'
import { useTheme } from './hooks/useTheme'
import './App.css'
import WarehousesList from './containers/warehouse'
import { Register } from './containers/Register'
import { QueryClient, QueryClientProvider } from 'react-query'

switch (localStorage.getItem(LANGUAGE_STORAGE_KEY)) {
  case 'vi':
  default:
    moment.locale('vi', {
      relativeTime: {
        s: '%d giây',
        m: '%d phút',
        h: '%d giờ',
        d: '%d ngày',
        w: '%d tuần',
        M: '%d tháng',
        y: '%d năm',
      },
    })

    numeral.register('locale', 'vi', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function (number) {
        return ''
      },
      currency: {
        symbol: '₫',
      },
    })
    numeral.locale('vi')
    break
}
const queryClient = new QueryClient()
function App() {
  const currentCtx = useContext(CurrentContext)
  const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()
  const [theme] = useTheme()

  useEffect(() => {
    document.body.classList.remove('dark')
    document.body.classList.remove('light')
    document.body.classList.add(theme)
  }, [theme])

  useEffect(() => {
    if (currentCtx?.config) {
      const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = currentCtx.config
      setCurrentConfigServices((prevState: any) => ({
        ...prevState,
        enableShipment: shipmentConfig?.enable,
        enableOrder: orderConfig?.enable,
        enableGeneral: generalConfig?.enable,
        enablePeerPayment: peerPaymentConfig?.enable,
        enableGsa: gsaConfig?.enable,
      }))
    }
  }, [currentCtx])

  return (
    <div
      id={'app-main'}
      className={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path={'/authentication'}
                element={<Authentication />}
              />
            </Routes>
          </BrowserRouter>

          <HashRouter>
            <Routes>
              {!isEmpty(currentConfigServices) && (
                <Route
                  path="*"
                  element={<PageNotFound />}
                />
              )}
              <Route
                path={'/register'}
                element={<Register />}
              />

              <Route
                path={'/login'}
                element={<Login />}
              />

              <Route
                path={'/'}
                element={
                  <AuthenticateRoute>
                    <CurrentConfigProvider>
                      <Dashboard />
                    </CurrentConfigProvider>
                  </AuthenticateRoute>
                }
              />

              {currentConfigServices?.enableOrder && (
                <>
                  <Route
                    path={'/orders/:code'}
                    element={
                      <AuthenticateRoute>
                        <OrderDetail />
                      </AuthenticateRoute>
                    }
                  />
                  <Route
                    path={'/orders'}
                    element={
                      <AuthenticateRoute>
                        <Orders />
                      </AuthenticateRoute>
                    }
                  />
                  <Route
                    path={'/shopping-cart'}
                    element={
                      <AuthenticateRoute>
                        <ShoppingCart />
                      </AuthenticateRoute>
                    }
                  />
                </>
              )}

              <Route
                path={'/warehouselist'}
                element={
                  <AuthenticateRoute>
                    <WarehousesList />
                  </AuthenticateRoute>
                }
              />

              <Route
                path={'/transactions'}
                element={
                  <AuthenticateRoute>
                    <Transaction />
                  </AuthenticateRoute>
                }
              />

              <Route
                path={'/packages'}
                element={
                  <AuthenticateRoute>
                    <Packages />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/delivery'}
                element={
                  <AuthenticateRoute>
                    <RequestDelivery />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/delivery/create'}
                element={
                  <AuthenticateRoute>
                    <CreateRequestDelivery />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/delivery/create-success'}
                element={
                  <AuthenticateRoute>
                    <Success />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/claims'}
                element={
                  <AuthenticateRoute>
                    <Claims />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/claims/create'}
                element={
                  <AuthenticateRoute>
                    <ClaimCreate />
                  </AuthenticateRoute>
                }
              />
              <Route
                path={'/claims/:claimCode'}
                element={
                  <AuthenticateRoute>
                    <ClaimDetail />
                  </AuthenticateRoute>
                }
              />
              <Route
                path="/delivery-notes"
                element={
                  <AuthenticateRoute>
                    <InventoryVoucher />
                  </AuthenticateRoute>
                }
              />

              {currentConfigServices?.enableShipment && (
                <>
                  <Route
                    path="/shipments"
                    element={
                      <AuthenticateRoute>
                        <Shipments />
                      </AuthenticateRoute>
                    }
                  />
                  <Route
                    path="/shipments/:code"
                    element={
                      <AuthenticateRoute>
                        <Shipment />
                      </AuthenticateRoute>
                    }
                  />
                  <Route
                    path="/shipments/create-consignment"
                    element={
                      <AuthenticateRoute>
                        <Consignment />
                      </AuthenticateRoute>
                    }
                  />
                </>
              )}

              <Route
                path="/profile"
                element={
                  <AuthenticateRoute>
                    <Profile />
                  </AuthenticateRoute>
                }
              />
            </Routes>
          </HashRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
