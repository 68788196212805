import { Skeleton, Radio, RadioChangeEvent } from 'antd'

interface IProps {
  warhorse: string
  fetchWarehouseData: any
  setWarhorse: (value: string) => void
}

export const TransportOptions: React.FC<IProps> = ({ fetchWarehouseData, warhorse, setWarhorse }) => {
  return (
    <div className="flex flex-col position-re">
      <span className={`mg-bt-16 fsz-16 line-h-24 robotomedium`}>Tuỳ Chọn Vận Chuyển</span>
      <div className="">
        <div className="flex mg-bt-16">
          <span
            className="mg-r-8 txt-muted"
            style={{ flex: '0 0 140px' }}>
            Kho nhận hàng tại TQ:
          </span>
          <Skeleton
            loading={fetchWarehouseData.loading}
            active>
            {!fetchWarehouseData.error ? (
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setWarhorse(e.target.value)
                }}
                value={warhorse}>
                {fetchWarehouseData.data?.map((x: any, idx: any) => (
                  <Radio
                    key={x.code}
                    className="!mr-20"
                    value={x.code}>
                    {x.name}
                  </Radio>
                ))}
              </Radio.Group>
            ) : (
              <span>Empty</span>
            )}
          </Skeleton>
        </div>
      </div>
    </div>
  )
}
