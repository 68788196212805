import React, { useMemo } from 'react'
import { Checkbox } from 'antd'
import { ServiceInterface } from '../mock-data'

interface OtherServicesSectionProps {
  services: any[] // Define the correct type
  handleChangeOtherService: (value: string, code: string) => void
  fetchOtherServices: any // Define the correct type
  collapseOtherServices: boolean
  setCollapseOtherService: (value: boolean) => void
}

const OtherServicesSection: React.FC<OtherServicesSectionProps> = ({
  services,
  handleChangeOtherService,
  fetchOtherServices,
  collapseOtherServices,
  setCollapseOtherService,
}) => {
  const selectedValues = useMemo(() => {
    const selectedValues: { [key in string]: string } = {}

    services.forEach((service) => {
      selectedValues[service.groupCode] = service.code
    })
    return selectedValues
  }, [services])

  return (
    <div className={'mg-t-16'}>
      {!collapseOtherServices && (
        <div className={'flex flex-col'}>
          <span className={'fsz-16 line-h-24 robotomedium mg-bt-16'}>Tiêu chí khác</span>
          {fetchOtherServices.data?.group.map((g: any, index: number) => {
            return (
              <div
                className={'mg-bt-18 flex'}
                key={g.id}>
                <span
                  className="mg-r-8 txt-muted"
                  style={{ flex: '0 0 140px' }}>
                  {g.name}:{' '}
                </span>

                <Checkbox.Group
                  className="flex-wrap flex-grow-1 flex-impt"
                  onChange={(value: any) => {
                    handleChangeOtherService(value?.at(-1) as string, g.code)
                  }}
                  value={[selectedValues[g.code]]}>
                  {g.services?.map((item: ServiceInterface) => {
                    return (
                      <Checkbox
                        style={{
                          flex: '0 0 250px',
                        }}
                        value={item.code}
                        key={item.id}>
                        {item.name}
                      </Checkbox>
                    )
                  })}
                </Checkbox.Group>
              </div>
            )
          })}
        </div>
      )}
      <div
        className={'cursor-pointer flex align-items-center justify-content-center pd-14 fsz-14 txt-secondary-hover'}
        onClick={() => {
          setCollapseOtherService(!collapseOtherServices)
        }}>
        {!collapseOtherServices ? (
          <span>
            <span className={'mg-r-4'}>
              <i className="fa-solid fa-angle-up" />
            </span>
            <span>Thu gọn</span>
          </span>
        ) : (
          <span>
            <span className={'mg-r-8'}>
              <i className="fa-solid fa-angle-down" />
            </span>
            <span>Xem thêm tiêu chí</span>
          </span>
        )}
      </div>
    </div>
  )
}

export default OtherServicesSection
