import { Col, Row } from 'antd'
import lodash from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import M22Box from '../../../components/M22Box'
import Address from '../../../components/ShoppingCart/Address/AddressList'
import CreateAddressModal from '../../../components/ShoppingCart/Address/CreateAddressModal'
import DeliveryAddressInterface from '../../../Interface/DeliveryAddressInterface'
import { cartRepository } from '../../../repositories/CartRepository'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import { ServiceInterface } from './mock-data'
import { useTheme } from '../../../hooks/useTheme'
import useFetch from '../../../hooks/useFetch'
import { shipmentCategoriesRepository } from '../../../repositories/ShipmentCategoriesRepository'
import TransportProviderCriteria from '../../../Criterias/TransportProviderCriteria'
import './create.styles.scss'
import { AxiosError } from 'axios'
import { IProviderTransport } from 'src/Interface/ProviderInterface'
import { OrderFees } from './components/OrderFees'
import { OrderProviders } from './components/OrderProviders'
import { TransportOptions } from './components/TransportOptions'
import { AddressOptions } from './components/Address'
import { ProductInfo } from './components/ProductInfo'
import OtherServicesSection from './components/OtherServicesSection'
import { IBodyGetShipmentTransportFees } from 'src/repositories/types/shipments'
import { TransportRepository } from 'src/repositories/TransportRepository'
import { ITransportFee } from 'src/Interface/ShipmentFeeInterface'

let timeout: any = null

const Consignment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [theme] = useTheme()

  const [services, setServices] = useState<ServiceInterface[]>([])
  const [valueOfProduct, setValueOfProduct] = useState('')
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddressInterface[]>()
  const [noticeWarehouse, setNoticeWarehouse] = useState('')
  const [selectedAddress, setSelectedAddress] = useState<any>()
  const [providers, setProviders] = useState<IProviderTransport[]>([])
  const [categoriesProviders, setCategoriesProviders] = useState([])
  const [addressSupported, setAddressSupported] = useState(true)
  const [connections, setConnections] = useState([])
  const [expectedPackages, setExpectedPackages] = useState<any>('')

  const [openAddressList, setOpenAddressList] = useState(false)
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allowCreate, setAllowCreate] = useState(false)
  const [collapseOtherServices, setCollapseOtherService] = useState(false)
  const [checkConnectProvider, setcheckConnectProvider] = useState(false)
  const [loadingCreateShipment, setLoadingCreateShipment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Giá trị trong form
  const [industryGroup, setIndustryGroup] = useState<any>([])
  const [weight, setWeight] = useState<any>('')
  const [volume, setVolume] = useState<any>('')
  const [formGroup, setFormGroup] = useState<any>('')
  const [hsCode, setHsCode] = useState<string | undefined>('')
  const [warhorse, setWarhorse] = useState('')
  const [provider, setProvider] = useState('')
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressInterface>()
  const [refShipmentCode, setRefShipmentCode] = useState('')
  const [refCustomerCode, setRefCustomerCode] = useState('')

  const [transportFees, setTransportFees] = useState<ITransportFee[]>()

  const fetchWarehouseData = useFetch(shipmentCategoriesRepository.getShipmentWarehouses)
  const fetchRoutesData = useFetch(shipmentCategoriesRepository.getShipmentRoutes)
  const fetchCompensationsData = useFetch(shipmentCategoriesRepository.getCompensations)
  const fetchTransportTypesData = useFetch(shipmentCategoriesRepository.getTransportShipmentTypes)
  const fetchOtherServices = useFetch(shipmentCategoriesRepository.getOtherProviderService)

  useEffect(() => {
    let warehouseData = fetchWarehouseData.data
    if (warehouseData && !warhorse) {
      setWarhorse(warehouseData[0]?.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoutesData, fetchWarehouseData])

  const getIndustryGroup = useCallback(async () => {
    try {
      const res = await categoryRepository.getIndustryGroup()
      setIndustryGroup(res)
    } catch (error) {
      M24ErrorUtils.showError(t, error)
    }
  }, [t])

  useEffect(() => {
    getIndustryGroup()
  }, [getIndustryGroup])

  const getAddressList = (isReset?: boolean) => {
    cartRepository
      .getAddressList()
      .then((response) => {
        if (response.length > 0) {
          const defaultAddress = response.find((x: any) => x.isDefault)
          setDeliveryAddresses([defaultAddress, ...response.filter((x: any) => !x.isDefault)])
          if (!deliveryAddress || isReset) {
            let temp = response.find((x: any) => x.isDefault)
            setDeliveryAddress(temp)
          } else {
            let temp = response.find((x: any) => x.id === deliveryAddress.id)
            setDeliveryAddress(temp)
          }
        } else {
          setDeliveryAddresses([])
        }
      })
      .catch(() => {})
      .finally(() => {})
  }

  const handleCloseAddressList = () => {
    setOpenAddressList(false)
  }

  const openAddressCreate = () => {
    setOpenAddressList(false)
    setOpenModalAdd(true)
  }

  const showModalEditAddress = (item: any) => {
    setOpenAddressList(false)
    setSelectedAddress(item)
    setOpenModalAdd(true)
  }

  const makeDeliveryAddress = (address: any) => {
    setDeliveryAddress(address)
  }

  const handleCloseAddressCreate = () => {
    setOpenModalAdd(false)
    setSelectedAddress(null)
    if (deliveryAddresses && deliveryAddresses.length > 0) setOpenAddressList(true)
    else setOpenAddressList(false)
  }

  const onSubmitCreateModal = (body: any) => {
    setIsLoading(true)
    if (selectedAddress) {
      cartRepository
        .updateAddress(selectedAddress.id, body)
        .then(() => {
          M24Notification.messageSuccess(t('message.success'))
          getAddressList()
          handleCloseAddressCreate()
          setSelectedAddress(null)
          setOpenAddressList(true)
        })
        .catch((error) => {
          M24ErrorUtils.showError(t, error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      cartRepository
        .createAddress(body)
        .then(() => {
          M24Notification.messageSuccess(t('message.success'))
          if (deliveryAddresses && deliveryAddresses?.length > 0) {
            setOpenAddressList(true)
          }
          getAddressList()
          handleCloseAddressCreate()
        })
        .catch((error) => {
          M24ErrorUtils.showError(t, error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const getSuitableProviders = useCallback(() => {
    let body: TransportProviderCriteria = {
      location: deliveryAddress?.location,
      weight,
      volume,
    }
    if (warhorse) {
      body.receiveWarehouse = warhorse
    }
    if (formGroup) {
      body.transportShipmentType = formGroup
    }
    if (hsCode) {
      body.industryGroup = hsCode
    }
    setProvider('')
    setIsLoading(true)
    shipmentCategoriesRepository
      .getProvidersByCriteria({
        ...body,
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setProviders(response.data)
          setProvider(response.data[0]?.provider)
        } else {
          setProviders([])
        }
      })
      .catch(() => {
        setcheckConnectProvider(true)
      })
      .finally(() => setIsLoading(false))
  }, [weight, volume, deliveryAddress, hsCode, formGroup, warhorse])

  const getCategoriesProviders = async () => {
    try {
      const res = await categoryRepository.getProvider()
      setCategoriesProviders(res)
    } catch (error) {}
  }

  const validAddress = useCallback(() => {
    setIsLoading(true)
    if (!provider) return
    cartRepository
      .checkAddressSupport({
        location: deliveryAddress?.location,
        provider: provider,
      })
      .then(() => {
        setAddressSupported(true)
      })
      .catch((err: AxiosError) => {
        setAddressSupported(false)

        if (err.response?.status === 500) return

        M24ErrorUtils.showError(t, err, {
          value: lodash.get(
            providers.find((x: any) => x.code === provider),
            'connection.provider.name',
            ''
          ),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [deliveryAddress?.location, provider, providers, t])

  const getConnections = async () => {
    try {
      const res = await supplierRepository.getListAccount()
      setConnections(res.filter((x: any) => x.status === 'CONNECTED' && x.provider.shipmentServices[0].enable))
    } catch (error) {}
  }

  const submitCreateShipmentHandler = useCallback(async () => {
    setIsSubmitted(true)
    let curServices = [formGroup].filter((x) => !!x)
    if (services.length > 0) {
      let listId: any = []
      services?.forEach((item: any) => listId.push(item.code))
      curServices = [...curServices, ...listId]
    }

    if (provider && deliveryAddress) {
      const curConnection: any = connections.find((x: any) => x.provider.code === provider)
      const addressId = deliveryAddress.id
      setLoadingCreateShipment(true)
      try {
        await shipmentRepository.createShipments({
          hsCode,
          provider,
          providerUsername: curConnection?.name,
          midMiles: [providers[0]?.midMileView.code],
          addressId,
          refCustomerCode,
          expectedPackages,
          refShipmentCode,
          services: curServices,
          warehouseCode: warhorse,
          fees: getFeePayload()
        })
        M24Notification.messageSuccess(t('shipments.createSuccess'), '', 6)
        setLoadingCreateShipment(false)
        navigate('/shipments', { replace: true })
      } catch (err: any) {
        setLoadingCreateShipment(false)
        const title = err?.response?.data?.title
        if (title === 'commodity_provider_not_found' || title === 'hs_code_not_found') {
          M24Notification.notifyErrorAllOptions({
            description: t('shipments.commodity_provider_not_found'),
          })
        }
        if (!curConnection) {
          M24Notification.notifyErrorAllOptions({
            description: t('shipments.notConnectToShipment'),
          })
        }
        // console.log(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections, deliveryAddress, expectedPackages, formGroup, navigate, provider, refCustomerCode, refShipmentCode, services, t, warhorse])

  // get toàn bộ nhóm dịch vụ
  const serviceOptions = useMemo(() => {
    const otherServicesData = fetchOtherServices.data?.group
    let tempArr: ServiceInterface[] = []
    otherServicesData?.forEach((g: any) => {
      if (g.services && g.services.length > 0) {
        g.services.forEach((s: ServiceInterface) => {
          tempArr.push(s)
        })
      }
    })
    return tempArr
  }, [fetchOtherServices.data?.group])

  const handleChangeOtherService = (code: string, group: string) => {
    let currentSelectedServices = [...services]

    let selectedService = serviceOptions.find((i: any) => i.code === code) || ({} as ServiceInterface)

    const indexIfExist = currentSelectedServices.findIndex((s) => s.groupCode === group)

    if (indexIfExist !== -1) {
      currentSelectedServices.splice(indexIfExist, 1, selectedService)
    } else {
      currentSelectedServices.push(selectedService)
    }
    setServices(currentSelectedServices.filter((s) => s.id))
  }

  const handleClearFormData = () => {
    setHsCode('')
    setWeight('')
    setVolume('')
    setFormGroup('')
    setValueOfProduct('')
    setServices([])
    setCollapseOtherService(true)
    setProvider('')
    setWarhorse(fetchWarehouseData.data[0]?.code)
  }

  const getFeePayload = useCallback(() => {
    if (!deliveryAddress) return

    let curServices = [formGroup].filter((x) => !!x)
    if (services.length > 0) {
      let listId: any = []
      services?.forEach((item: any) => listId.push(item.code))
      curServices = [...curServices, ...listId]
    }

    const payload: IBodyGetShipmentTransportFees = {
      groupCode: 'shipment_fee',
      industryGroup: hsCode!,
      weight,
      volume,
      transportShipmentType: formGroup,
      location: deliveryAddress.location,
      services: curServices,
      receiveWarehouse: warhorse,
      packageCount: expectedPackages,
    }
    return payload
  }, [deliveryAddress, expectedPackages, formGroup, hsCode, services, volume, warhorse, weight])

  const getTransportFees = useCallback(() => {
    const payload = getFeePayload()
    if (!payload) return

    TransportRepository.getTransportFees(payload).then((res) => {
      setTransportFees(res.data)
    })
  }, [getFeePayload])

  useEffect(() => {
    if (timeout) clearTimeout(timeout)
    if (!weight || !volume || !deliveryAddress) {
      setTransportFees(undefined)
      return
    }

    timeout = setTimeout(() => {
      getTransportFees()
    }, 300)
  }, [weight, volume, warhorse, getTransportFees, deliveryAddress])

  useEffect(() => {
    if (!deliveryAddress) {
      return
    }
    getSuitableProviders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSuitableProviders])

  useEffect(() => {
    getCategoriesProviders()
    getConnections()
    getAddressList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (deliveryAddress?.location && provider) {
      validAddress()
    }
  }, [validAddress, deliveryAddress?.location, provider])

  useEffect(() => {
    const disabled =
      !hsCode || !weight || !volume || !formGroup || !valueOfProduct || !provider || !addressSupported || isLoading || providers.length === 0
    setAllowCreate(disabled)
  }, [addressSupported, formGroup, hsCode, isLoading, valueOfProduct, volume, weight, provider, providers])

  return (
    <MainLayout>
      <Row
        gutter={[16, 16]}
        className="consignment">
        <Col span={17}>
          <M22Box className="mg-bt-16 pd-16">
            <div className="flex flex-col">
              <div className={'flex justify-content-between'}>
                <span className="fsz-16 line-h-24 robotomedium mg-bt-16">{t('consignment.descriptions')}</span>
                <span
                  className={`cursor-pointer txt-secondary-hover px-0 ${theme} fsz-14`}
                  onClick={() => handleClearFormData()}>
                  <i className="fa-regular fa-rotate-right mg-r-6"></i>
                  {t('filter.refresh')}
                </span>
              </div>

              <ProductInfo
                hsCode={hsCode}
                formGroup={formGroup}
                valueOfProduct={valueOfProduct}
                weight={weight}
                volume={volume}
                isSubmitted={isSubmitted}
                industryGroup={industryGroup}
                fetchTransportTypesData={fetchTransportTypesData}
                setHsCode={setHsCode}
                setFormGroup={setFormGroup}
                setValueOfProduct={setValueOfProduct}
                setWeight={setWeight}
                setVolume={setVolume}
              />

              <TransportOptions
                warhorse={warhorse}
                fetchWarehouseData={fetchWarehouseData}
                setWarhorse={setWarhorse}
              />

              <AddressOptions
                deliveryAddresses={deliveryAddresses}
                deliveryAddress={deliveryAddress}
                noticeWarehouse={noticeWarehouse}
                setOpenAddressList={setOpenAddressList}
                setOpenModalAdd={setOpenModalAdd}
                setNoticeWarehouse={setNoticeWarehouse}
              />

              <OtherServicesSection
                services={services}
                fetchOtherServices={fetchOtherServices}
                collapseOtherServices={collapseOtherServices}
                setCollapseOtherService={setCollapseOtherService}
                handleChangeOtherService={handleChangeOtherService}
              />
            </div>
          </M22Box>

          <OrderProviders
            hsCode={hsCode}
            weight={weight}
            volume={volume}
            valueOfProduct={valueOfProduct}
            formGroup={formGroup}
            warhorse={warhorse}
            compensationRate={''}
            providers={providers}
            categoriesProviders={categoriesProviders}
            fetchCompensationsData={fetchCompensationsData}
            currency={''}
            provider={provider}
            setProvider={setProvider}
            industryGroup={industryGroup}
            isLoading={isLoading}
            checkConnectProvider={checkConnectProvider}
            fetchTransportTypesData={fetchTransportTypesData}
            fetchWarehouseData={fetchWarehouseData}
          />
        </Col>

        <OrderFees
          transportFees={transportFees}
          allowCreate={allowCreate}
          expectedPackages={expectedPackages}
          volume={volume}
          isSubmitted={isSubmitted}
          refShipmentCode={refShipmentCode}
          refCustomerCode={refCustomerCode}
          loadingCreateShipment={loadingCreateShipment}
          submitCreateShipmentHandler={submitCreateShipmentHandler}
          setRefCustomerCode={setRefCustomerCode}
          setRefShipmentCode={setRefShipmentCode}
          setExpectedPackages={setExpectedPackages}
        />
        {openAddressList && (
          <Address
            isVisible={openAddressList}
            onCancel={handleCloseAddressList}
            openAddressCreate={openAddressCreate}
            addressList={deliveryAddresses}
            updateAddress={showModalEditAddress}
            getAddressList={getAddressList}
            makeDeliveryAddress={makeDeliveryAddress}
            deliveryAddress={deliveryAddress}
          />
        )}

        {openModalAdd && (
          <CreateAddressModal
            isVisible={openModalAdd}
            onCancel={handleCloseAddressCreate}
            onSubmit={onSubmitCreateModal}
            datasource={selectedAddress}
            isDefault={deliveryAddresses?.length === 0}
            loading={isLoading}
          />
        )}
      </Row>
    </MainLayout>
  )
}

export default Consignment
