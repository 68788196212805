import {Badge, Button, Tooltip} from 'antd'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {DATA_TABLE_LAYOUT, DATA_TABLE_LIST} from '../../../core/config'
import {useTheme} from '../../../hooks/useTheme'
import QuickPagination from "../../../components/QuickPagination";

interface HeaderListProps {
    total: number
    typeDataTable: string
    setTypeDataTable: (val: string) => void
    filter?: any
    pagination?: any
    onPageChange: (filter: any) => void;
}

const HeaderList: React.FC<HeaderListProps> = (props) => {
    const {
      total, typeDataTable, setTypeDataTable,
        filter,pagination, onPageChange
    } = props
    const [theme] = useTheme()
    const navigate = useNavigate()
    const {t} = useTranslation()


    return (
        <div className="flex justify-content-between">
            <div className="dpl-flex align-items-center">
                <h3 className="robotomedium mg-t-0 mg-bt-0 fsz-16 line-h-24 mg-l-8 mg-r-4">{t('shipments.list')}</h3>
                <Badge
                    className="mg-r-20"
                    count={total}
                    overflowCount={99999}
                    showZero={true}
                    style={{
                        backgroundColor: !total ? '#F5F5F5' : '',
                        color: !total ? '#707070' : '',
                    }}
                />
                <Tooltip title={<span className="fsz-12 line-h-20">{t('shipments.tableLayout')}</span>}>
          <span className={`heading-data-type ${theme} ${typeDataTable === DATA_TABLE_LAYOUT ? 'active' : ''}`}>
            <i
                className={`fa-light  fa-table-list`}
                onClick={() => setTypeDataTable(DATA_TABLE_LAYOUT)}></i>
          </span>
                </Tooltip>
                <Tooltip title={<span className=" fsz-12 line-h-20">{t('shipments.listLayout')}</span>}>
          <span className={`heading-data-type ${theme} ${typeDataTable === DATA_TABLE_LIST ? 'active' : ''}`}>
            <i
                className={`${
                    typeDataTable === DATA_TABLE_LIST ? 'txt-color-orange3' : ''
                } fsz-16 line-h-16  fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
                onClick={() => setTypeDataTable(DATA_TABLE_LIST)}></i>
          </span>
                </Tooltip>
            </div>
            <div className={'flex'}>
                <QuickPagination
                    filter={filter}
                    pagination={pagination}
                    onPageChange={onPageChange}
                />
                <Button
                    className={`m-l-3 `}
                    type="ghost"
                    icon={<i className="fa-regular fa-plus mg-r-10"></i>}
                    onClick={() => navigate('/shipments/create-consignment', {replace: true})}>
                    {t('shipments.createShipments')}
                </Button>
            </div>
        </div>
    )
}

export default HeaderList
