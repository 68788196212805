import { theme, Radio, Avatar, Skeleton } from 'antd'
import { t } from 'i18next'
import EmptyDataFilter from 'src/components/Empty/EmptyDataFilter'
import M22Box from 'src/components/M22Box'
import { formatMoneyByUnit } from 'src/core/helpers/string'

interface IProps {
  hsCode: string | undefined
  weight: number
  volume: number
  valueOfProduct: string | undefined
  formGroup: string
  warhorse: string
  compensationRate: string
  providers: any // Thay Provider bằng kiểu dữ liệu tương ứng
  categoriesProviders: any // Thay CategoryProvider bằng kiểu dữ liệu tương ứng
  fetchCompensationsData: any // Thay any bằng kiểu dữ liệu tương ứng
  currency: string
  provider: string
  setProvider: (value: string) => void
  industryGroup: any
  isLoading: boolean
  checkConnectProvider: boolean
  fetchTransportTypesData: any // Thay any bằng kiểu dữ liệu tương ứng
  fetchWarehouseData: any // Thay any bằng kiểu dữ liệu tương ứng
}

export const OrderProviders: React.FC<IProps> = ({
  hsCode,
  weight,
  volume,
  valueOfProduct,
  formGroup,
  warhorse,
  compensationRate,
  providers,
  categoriesProviders,
  fetchCompensationsData,
  currency,
  provider,
  industryGroup,
  setProvider,
  isLoading,
  checkConnectProvider,
  fetchTransportTypesData,
  fetchWarehouseData,
}) => {
  return (
    <M22Box className="pd-16">
      <div className="flex flex-col">
        <span className="fsz-16 robotomedium line-h-24">Danh Sách Gói Dịch Vụ & Nhà Cung Cấp</span>
        <span className={`txt-color-gray8 mg-bt-12 mg-t-8 ${theme}`}>Dịch vụ sẽ hiển thị nhà cung cấp phù hợp với những tiêu chí bạn đưa ra !!!</span>
        <M22Box>
          <span className="flex fsz-14 line-h-20 txt-colo-black robotomedium align-items-start whitespace">
            {!hsCode && !weight && !volume && !valueOfProduct && !formGroup && !warhorse && !compensationRate ? (
              <span className={`txt-color-gray8 mg-l-4 ${theme}`}>{t('consignment.notice')}</span>
            ) : (
              <div className="flex flex-wrap align-items-center mg-l-4">
                {hsCode && <span className={`condition-service ${theme}`}>{industryGroup?.find((i: any) => i.code === hsCode)?.name}</span>}
                {formGroup && (
                  <span className={`condition-service ${theme}`}>{fetchTransportTypesData?.data?.find((i: any) => i.code === formGroup)?.name}</span>
                )}
                {warhorse && <span className={`condition-service ${theme}`}>{fetchWarehouseData?.data?.find((i: any) => i.code === warhorse)?.name}</span>}
                {compensationRate && <span className={`condition-service ${theme}`}>{t(`consignment.${compensationRate}`)}</span>}
              </div>
            )}
          </span>
        </M22Box>

        {providers.length > 0 ? (
          <Radio.Group
            className="flex-col flex-impt"
            onChange={(e) => {
              setProvider(e.target.value)
            }}
            value={provider}>
            {providers.map((p: any) => {
              const curProviderCategory: any = categoriesProviders.find((categoriesProvider: any) => categoriesProvider.code === p.provider)
              const providerName = curProviderCategory?.name
              const providerLogo = curProviderCategory?.logo
              const providerDesc = curProviderCategory?.description
              const compensation = fetchCompensationsData?.data?.find((i: any) => i.code === p.compensation)

              return (
                <Radio
                  onChange={(e) => {
                    setProvider(e.target.value)
                  }}
                  checked={p.provider === provider}
                  key={p.provider}
                  value={p.provider}
                  className={`pd-16 m22-border-base rad-6 mg-bt-16 flex-impt align-items-center-impt consignment provider-radio_${theme}`}>
                  <div className="flex mg-l-24">
                    <div className="flex flex-col justify-content-center">
                      <Avatar
                        src={providerLogo}
                        size={64}
                        shape="square"
                        className="mg-r-24"
                      />
                    </div>
                    <div className="flex flex-col justify-content-center gap-4">
                      <div className={`flex flex-row align-items-end ${theme}`}>
                        <span className="fsz-24 line-h-28 robotomedium">{providerName} /</span>
                        <span className="mg-l-8 fsz-12 robotoregular txt-secondary">{providerDesc}</span>
                      </div>
                      <div className={'mg-t-4'}>
                        <div className={'flex-row mg-bt-4'}>
                          <span className="fsz-14 robotomedium">Vận chuyển Midmile: </span>
                          <span className={'fsz-14 mg-l-4'}>
                            <Avatar
                              src={p.midMileView?.logo}
                              size={24}
                              className="mg-r-4"
                            />
                            <span>{p.midMileView?.name}</span>
                          </span>
                        </div>
                        {hsCode && weight && volume && valueOfProduct && formGroup && (
                          <>
                            <div className={'flex-row mg-bt-4'}>
                              <span className="fsz-14 robotomedium">{t('consignment.providerCompensation')}:</span>
                              <span className={'fsz-14 mg-l-4'}> {compensation?.name}</span>
                            </div>
                            <div className={'flex flex-row mg-bt-4'}>
                              <span className="fsz-14 robotomedium">{t('consignment.chargeInformation')}: </span>
                              <span className={'mg-r-4 mg-l-4'}>{formatMoneyByUnit(p.volumeUnitPrice, currency)}/m3</span>(
                              <span className={''}>{formatMoneyByUnit(p.weightUnitPrice, currency)}/kg</span>)
                            </div>
                            <div className={'flex flex-row mg-bt-4'}>
                              <span className="fsz-14 robotomedium">{t('consignment.timeShipping')}: </span>
                              <span className={'mg-l-4 fsx-14'}> {p.timeShippingDisplay} ngày</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Radio>
              )
            })}
          </Radio.Group>
        ) : (
          <div className={'mg-t-12 mg-bt-24'}>
            {!isLoading ? (
              checkConnectProvider ? (
                <EmptyDataFilter description={t('consignment.connectionInvalid')} />
              ) : (
                <EmptyDataFilter description="Chưa có gói dịch vụ và nhà cung cấp phù hợp, hãy thử lại!" />
              )
            ) : (
              <Skeleton></Skeleton>
            )}
          </div>
        )}
      </div>
    </M22Box>
  )
}
