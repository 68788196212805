import { API_BASE_URL } from '../core/config'
import { httpConfig } from '../core/config/http'
import { url } from '../core/helpers/string'
import { Repository } from '../core/repositories/Repository'
import { IBodyGetShipmentTransportFees } from './types/shipments'

export class TarnsportRepositoryClass extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, 'transports'))
  }

  getTransportFees = (data: IBodyGetShipmentTransportFees) => {
    return this.http.post('/fees', {...data, m22Source: 'WEB'})
  }
}
export const TransportRepository: TarnsportRepositoryClass = new TarnsportRepositoryClass()
