import { Tabs, Tooltip } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import MainLayout from '../../components/Layout'
import M22Box from '../../components/M22Box'
import { CurrentContext } from '../../context/CurrentContext'
import { useTheme } from '../../hooks/useTheme'
import IMGONBOARD from '../../resources/images/cuate.png'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import lodash from 'lodash';
import { localStorageRead } from 'src/utils/LocalStorageUtils'

function Dashboard() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  // const [currentStep, setCurrentStep] = useState(0)
  // const [currentInstruction, setCurrentInstruction] = useState('orderConfig')
  // const [installed, setInstalled] = useState(true)
  const username = lodash.get(localStorageRead('PROFILE'), 'username')

  const [theme] = useTheme()
  const currentCtx = useContext(CurrentContext)

  // useEffect(() => {
  //   checkExtension(ID_GO_BIZ_EXTENSION, 'injector.js', (ok: any) => {
  //     setInstalled(!!ok)
  //   })
  // }, [])

  // const tabsChangeHandler = (key: string) => {
  //   setCurrentInstruction(key)
  // }

  return (
    <MainLayout title={t('menu.dashboard')}>
      {/* OLD */}
      {/* <M22Box className={`pd-16 ${theme}`}>
        <div className="flex flex-col mg-bt-24">
          <span className={`m22-typo-heading robotomedium ${theme} rounded-lg `}>Hướng Dẫn Thiết Lập</span>
          <span className={`m22-typo-heading_gray ${theme}`}>Dưới đây là một vài nhiệm vụ Sabo khuyến khích kết thúc để kinh doanh thành công.</span>
        </div>
        <div className={`m22-steps ${theme}`}>
          <div
            className={`m22-step ${currentStep >= 0 ? 'active' : ''}`}
            onClick={() => setCurrentStep(0)}>
            <div className="m22-step-right">
              <div className="m22-step_content">
                <span>Cài đặt công cụ đặt hàng</span>
              </div>
            </div>
          </div>

          <div
            className={`m22-step ${!installed ? 'disabled' : ''} ${currentStep >= 1 ? 'active' : ''}`}
            onClick={() => {
              setCurrentStep(1)
            }}>
            <div className="m22-step-right">
              <div className="m22-step_content">
                <span>Tham khảo các hướng dẫn</span>
              </div>
            </div>
          </div>
          <div
            className={`m22-step ${!installed ? 'disabled' : ''} ${currentStep >= 2 ? 'active' : ''}`}
            onClick={() => {
              setCurrentStep(2)
            }}>
            <div className="m22-step-right">
              <div className="m22-step_content">
                <span className={``}>Tiến Hành Đặt Hàng</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`steps-guide ${theme}`}>
          {currentStep === 0 && (
            <div className="flex m22-step-animate">
              <Row gutter={36}>
                <Col span={12}>
                  <div className="flex flex-col mg-r-24">
                    <div
                      className={`mg-bt-4 ${theme}`}
                      style={{ width: '110%' }}>
                      <span className={'txt-secondary'}>Vui lòng sử dụng trình duyệt Chrome và cài đặt </span>
                      <span className={`m22-typo-content-md m22-typo-black ${theme} notice mg-l-4 mg-r-4`}>Sabo - Công cụ hỗ trợ người mua hàng</span>
                      bằng cách click vào
                      <span
                        className={'txt-secondary robotomedium mg-l-4'}
                        style={{ flexWrap: 'nowrap' }}>
                        “CÀI ĐẶT NGAY"
                      </span>
                      .{' '}
                    </div>
                    <div
                      style={theme === 'dark' ? { color: '#326de7' } : { color: 'blue' }}
                      className="cursor-pointer robotoregular mg-bt-16"
                      onClick={() => window.open(currentCtx?.config?.toolConfig?.video ?? '', '_blank')}>
                      Xem Video hướng dẫn
                    </div>
                    <Space>
                      <Button
                        size="large"
                        // style={{ width: 141 }}
                        className={`m22-btn ${theme}`}
                        type="primary"
                        icon={<i className="fa-solid fa-download mg-r-6"></i>}
                        onClick={() => window.open(`${currentCtx?.config?.toolConfig?.download}`, '_blank')}>
                        Cài Đặt Ngay
                      </Button>
                    </Space>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="flex flex-col align-items-center">
                    <div className="preview-extension-img">
                      <Image
                        src={currentCtx?.config?.toolConfig?.image ?? ''}
                        preview={false}
                        className=""
                      />
                    </div>
                    <span className={`m22-typo-heading_gray fsz-12 line-h-20 m22-typo-content-md mg-t-4 ${theme}`}>
                      *Ảnh công cụ được chụp từ Google Chrome
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {currentStep === 1 && (
            <Tabs
              className={`step-2-tabs ${theme} m22-step-animate`}
              type="card"
              activeKey={currentInstruction}
              onChange={tabsChangeHandler}
              items={[
                {
                  label: <span className={`robotomedium`}>Hướng dẫn mua hộ Order</span>,
                  key: 'orderConfig',
                  children: currentCtx?.config?.orderConfig?.enable ? (
                    <div className="flex">
                      <div
                        style={{ lineHeight: '1.67' }}
                        dangerouslySetInnerHTML={{ __html: currentCtx?.config?.orderConfig?.guide }}></div>
                      <Image
                        src={HDMH}
                        alt="huong-dan-mua-ho"
                        preview={false}
                        width={500}
                      />
                    </div>
                  ) : (
                    ''
                  ),
                },
                {
                  label: <span className={`robotomedium`}>Hướng dẫn ký gửi vận chuyển</span>,
                  key: 'shipmentConfig',
                  children: currentCtx?.config?.shipmentConfig?.enable ? (
                    <div className="flex flex-row">
                      <span
                        style={{ lineHeight: '1.67' }}
                        dangerouslySetInnerHTML={{ __html: currentCtx?.config?.shipmentConfig?.guide }}></span>
                      <Image
                        src={HDKG}
                        alt="huong-dan-mua-ho"
                        preview={false}
                        width={500}
                      />
                    </div>
                  ) : (
                    ''
                  ),
                },
                {
                  label: <span className={`robotomedium`}>Hướng dẫn thanh toán hộ</span>,
                  key: 'peerPaymentConfig',
                  children: currentCtx?.config?.peerPaymentConfig?.enable ? (
                    <span
                      style={{ lineHeight: '1.67' }}
                      dangerouslySetInnerHTML={{ __html: currentCtx?.config?.peerPaymentConfig?.guide }}></span>
                  ) : (
                    ''
                  ),
                },
              ].filter((item: any) => currentCtx?.config && currentCtx?.config[item.key]?.enable)}
            />
          )}

          {currentStep === 2 && (
            <div className="flex flex-col mg-bt-24 m22-step-animate">
              <span className="mg-bt-32 mg-t-16 robotobold"> Nền Tảng Đặt Hàng</span>
              <Row
                justify={'center'}
                gutter={24}
                wrap={false}
                className="flex justify-content-center">
                <Col span={6}>
                  <div className={`box-market ${theme}`}>
                    <div className="flex flex-col align-items-center">
                      <Image
                        height={52}
                        src={MotSauImg}
                        preview={false}
                      />
                      <span className={'robotomedium brand-name'}>1688.com</span>
                    </div>
                    <div
                      // size='large'
                      // type='text'
                      className={`m22-btn ${theme} robotomedium fsz-16`}
                      onClick={() => window.open('https://www.1688.com/', '_blank')}>
                      Đặt Hàng Ngay <i className="fa-solid fa-arrow-right mg-l-8"></i>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={`box-market ${theme} robotomedium fsz-16`}>
                    <div className="flex flex-col align-items-center">
                      <Image
                        height={52}
                        src={TaobaoImg}
                        preview={false}
                      />
                      <span className={'robotomedium brand-name'}>Taobao.com</span>
                    </div>
                    <div
                      // size='large'
                      // type='text'
                      className={`m22-btn ${theme} robotomedium fsz-16`}
                      onClick={() => window.open('https://world.taobao.com/', '_blank')}>
                      Đặt Hàng Ngay <i className="fa-solid fa-arrow-right mg-l-8"></i>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={`box-market ${theme}`}>
                    <div className="flex flex-col align-items-center">
                      <Image
                        height={52}
                        src={TmallImg}
                        preview={false}
                      />
                      <span className={'robotomedium brand-name'}>Tmall.com</span>
                    </div>
                    <div
                      // size='large'
                      // type='text'
                      className={`m22-btn ${theme} robotomedium fsz-16`}
                      onClick={() => window.open('https://www.tmall.com/', '_blank')}>
                      Đặt Hàng Ngay <i className="fa-solid fa-arrow-right mg-l-8"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>

        <div className="flex align-items-center mg-r-24 mg-t-40 ">
          <span className="m22-typo-heading_gray-secondary mg-r-8">{`Bạn đã hoàn thành: ${currentStep + 1}/3 tác vụ`}</span>
          <div className={`flex line-step`}>
            <span className={`line-step_child ${theme} ${currentStep >= 0 ? 'active' : ''}`}></span>
            <span className={`line-step_child ${theme} ${currentStep >= 1 ? 'active' : ''}`}></span>
            <span className={`line-step_child ${theme} ${currentStep === 2 ? 'active' : ''}`}></span>
          </div>
          {currentStep >= 1 && (
            <Button
              type="text"
              className={`ant-btn m22-btn ${theme} mg-r-16`}
              onClick={() => setCurrentStep(currentStep - 1)}>{`Quay Lại Bước ${currentStep}`}</Button>
          )}
          {currentStep < 2 && (
            <Button
              type="primary"
              className={`m22-btn ${theme}`}
              // disabled={!installed}
              onClick={() => setCurrentStep(currentStep + 1)}>
              Tiếp Tục
            </Button>
          )}
          {currentStep === 2 && <Button type='primary'>Tiến Hành Đặt hàng</Button>}
        </div>
      </M22Box> */}

      {/* New */}
      <div style={{ border: "1px solid #FCD535", borderRadius: "6px", marginBottom: "16px" }}>
        <M22Box className={`pd-16 ${theme} mg-bt-0 flex`}>
          <div className='basis-2/3'>
            {/* <div className='font-medium fsz-14 text-[#808080] '>Sáng ra em gửi lời chào</div> */}
            <h3 className='font-medium fsz-24 mg-t-8'>Xin chào, {username} </h3>
            <div className='fsz-14 text-[#7D7E7E] mg-t-14'>Dưới đây là một vài nhiệm vụ Sabo khuyến khích thiết lập để kinh doanh thành công.<br />
              Bỏ qua nếu bạn đã hoàn thành rồi.</div>
          </div>
          <div className='basis-1/3'>
            <img src={IMGONBOARD} alt='onboard' width={360} />
          </div>
        </M22Box>
      </div>
      <M22Box className={`pd-16 ${theme}`}>
        <Tabs
          className={`${theme} tabs-dashboard`}
          type="card"
          items={[
            {
              label: <span className={`robotomedium text-tabs-dashboard `}>Vận chuyển quốc tế </span>,
              key: 'shipmentConfig',
              children:
                <div>
                  <div className='font-medium fsz-14 text-[#808080] mg-bt-24 mg-t-12'>SABO hỗ trợ bạn 3 cách để tạo đơn vị vận chuyển quốc tế, phù hợp với từng nhu cầu sử dụng của bạn</div>
                  <div className='flex items-center justify-center gap-20'>
                    <div className='basis-1/3 rounded-md flex flex-col justify-center gap-10 items-center h-[200px] px-24 py-30' style={{ border: "1px solid #B3B3B3" }}>
                      <div className='font-medium fsz-14 text-tabs-dashboard '>Gửi hàng trực tiếp đến kho</div>
                      <div className='font-normal fsz-14 text-[#808080]'>Phù hợp với các giao dịch đặt hàng qua Wechat, mua hàng offline mà không qua nền tảng nào cả</div>
                      <a href="https://sabo.asia/huong-dan-su-dung-dich-vu-ky-gui-hang-truc-tiep/" target='_blank' rel="noreferrer">
                        <button style={{ borderRadius: "30px", border: "none", color: "#1a1a1a" }} className='px-24 py-12 hover:cursor-pointer bg-[#FFE063] mg-t-20'>
                        <i className="fa-solid fa-plus mg-r-6">
                          </i><span className='text-[#1a1a1a] fsz-14 font-medium'>Lên đơn ngay</span></button>
                      </a>
                    </div>

                    <div className='basis-1/3 rounded-md flex flex-col justify-center gap-10 items-center h-[200px] px-24 py-30' style={{ border: "1px solid #B3B3B3" }}>
                      <div className='font-medium fsz-14 text-tabs-dashboard '>Sử dụng extension để đặt hàng</div>
                      <div className='font-normal fsz-14 text-[#808080] fami' >Phù hợp khi mua hàng qua các nền tảng như taobao, 1688, aliexpress,...</div>
                      <button style={{ borderRadius: "30px", border: "none", color: "#1a1a1a" }} onClick={() => window.open(`${currentCtx?.config?.toolConfig?.download}`, '_blank')} className='px-24 py-12 hover:cursor-pointer bg-[#FFE063] mg-t-20' >
                        <i className="fa-solid fa-plus mg-r-6"></i><span className='text-[#1a1a1a] fsz-14 font-medium'>Lên đơn ngay</span>
                      </button>
                    </div>

                    <div className='basis-1/3 rounded-md flex flex-col justify-center gap-10 items-center h-[200px] px-24 py-30' style={{ border: "1px solid #B3B3B3" }}>
                      <div className='font-medium fsz-14 text-tabs-dashboard '>Lên đơn bằng giao diện SABO</div>
                      <div className='font-normal fsz-14 text-[#808080]'>Phù hợp với mặt hàng lô, cần khai báo chi tiết danh sách hàng hóa,...</div>
                      <button style={{ borderRadius: "30px", border: "none", color: "#1a1a1a" }} onClick={() => navigate("/shipments/create-consignment?back='/'")} className='px-24 py-12 hover:cursor-pointer bg-[#FFE063] mg-t-20'>
                        <i className="fa-solid fa-plus mg-r-6 bg-[#FFE063]"></i><span className='text-[#1a1a1a] fsz-14 font-medium'>Lên đơn ngay</span></button>
                    </div>

                  </div>
                </div>
            },
            {
              label: <Tooltip title="Dịch vụ sắp mở"> <span className={`robotomedium`}>Mua hộ</span></Tooltip>,
              key: 'orderConfig',
              disabled: true,
              children:
                <></>
            },
          ]}
        />

      </M22Box>
    </MainLayout>
  )
}

export default Dashboard
