import React, { ReactNode } from 'react'
interface AddressInfoProps {
  value?: any
  icon: ReactNode
  valueClasses?: string
  className?: string
}
const AddressInfo: React.FC<AddressInfoProps> = (props) => {
  const { icon, value, valueClasses, className } = props
  return (
    <div className={`${className ? className : ''} flex align-items-center `}>
      {icon}
      <span className={`info ${valueClasses ? valueClasses : ''}`}>{value ? value : '---'}</span>
    </div>
  )
}

export default AddressInfo
